import React, {useContext, createContext, useState} from "react";
import apiClient from './apiClient'
import axios from 'axios';

const TOKEN_KEY = "access_token"
const REFRESH_KEY = "refresh_token"
const CLUB_KEY = "club"

const Auth = {
    isAuthenticated: false,
    //API Вход
    signin(login, password, cb) {

        apiClient.post('/backoffice/auth/login', {
            login: login,
            password: password,
        }).then(response => {
            localStorage.setItem(TOKEN_KEY, response.data.access_token)
            localStorage.setItem(REFRESH_KEY, response.data.refresh_token)

            this.getProfile(cb)

        }).catch(error => {
            // console.error(error)
            cb(null, error)
        });

    },
    //API Выход
    signout(cb) {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(REFRESH_KEY)
        localStorage.removeItem(CLUB_KEY)
        Auth.isAuthenticated = false;
        cb(null)
    },
    //API обработка ошибки
    handleError(error) {
        if (error.response && error.response.status === 403) {
            if (error.response.data.code == "invalid_auth_token") {
                this.refreshToken(() => {
                    window.location.reload();
                })
            }
        }
    },
    //API обновление токена
    refreshToken(cb) {
        if (localStorage.getItem(REFRESH_KEY) == null) {
            this.signout(cb)
        } else {
            apiClient.post('/backoffice/auth/refresh', {}, {headers: {'Authorization': `Bearer ${localStorage.getItem(REFRESH_KEY)}`}}).then(response => {
                localStorage.setItem(TOKEN_KEY, response.data.access_token)
                this.getProfile(cb)

            }).catch(error => {
                // console.error(error)
                this.signout(() => {
                })
                cb(null, error)
            });
        }
    },
    //API восстановление авторизации
    restoreAuth(cb) {
        if (localStorage.getItem(TOKEN_KEY) == null) {
            this.signout(cb)
        } else {
            this.getProfile(cb)
        }
    },
    //API Получчение профиля пользователя
    getProfile(cb) {
        apiClient.get('/backoffice/account', {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                Auth.isAuthenticated = true;
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Загрузка клубов пользователя
    loadClubs(query, sort_field, ordering, page, size, cb) {
        var url = '/backoffice/account/clubs?query=' + query + "&page=" + page + "&size=" + size
        if (sort_field != null) {
            url += "&order_by=" + (sort_field) + "&ordering=" + (ordering ? "asc" : "desc")
        }
        apiClient.get(url, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Загрузка клуба
    loadClub(club_id, cb) {
        apiClient.get('/backoffice/club/' + club_id, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Загрузка членов клуба
    loadMembers(club_id, query, sort_field, ordering, page, size, cb) {
        var url = '/backoffice/club/' + club_id + '/members?query=' + query + "&page=" + page + "&size=" + size
        if (sort_field != null) {
            url += "&order_by=" + (sort_field) + "&ordering=" + (ordering ? "asc" : "desc")
        }
        apiClient.get(url, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Измненение баланса пользователя
    changeMemberBalance(club_id, member_account_id, diff, cb) {
        apiClient.post('/backoffice/club/' + club_id + '/change-members-balance?diff=' + diff.toFixed(2), [member_account_id], {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Загрузка истории игр
    loadGames(club_id, date_start, date_finish, done_reason_filter, query, sort_field, ordering, page, size, cb) {
        var url = '/backoffice/club/' + club_id + '/match-results?page=' + page + "&size=" + size;
        if (date_start != null && date_start != "") {
            url += '&start_date=' + date_start;
        }
        if (date_finish != null && date_finish != "") {
            url += "&end_date=" + date_finish;
        }
        if (sort_field != null) {
            url += "&order_by=" + (sort_field) + "&ordering=" + (ordering ? "asc" : "desc")
        }
        if (query != null && query != "") {
            url += "&query=" + query
        }
        if (done_reason_filter != null && done_reason_filter != "") {
            url += "&done_reason_filter=" + done_reason_filter
        }
        apiClient.get(url, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Загрузка игры
    loadGame(club_id, game_id, cb) {
        var url = '/backoffice/games/' + game_id ;
        apiClient.get(url, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Загрузка турниров
    loadTournaments(club_id, date_start, date_finish, status, sort_field, ordering, page, size, cb) {
        var url = '/backoffice/club/' + club_id + '/tournaments?page=' + page + "&size=" + size;
        // if (date_start != null && date_start != "") {
        //     url += '&start_date=' + date_start;
        // }
        // if (date_finish != null && date_finish != "") {
        //     url += "&end_date=" + date_finish;
        // }
        if (sort_field != null) {
            url += "&order_by=" + (sort_field) + "&ordering=" + (ordering ? "asc" : "desc")
        }
        if (status != null && status != "") {
            url += "&status=" + status
        }
        apiClient.get(url, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Загрузка транзакций
    loadTransactions(club_id, date_start, date_finish, query, sort_field, ordering, page, size, cb) {
        var url = '/backoffice/club/' + club_id + '/counter-history?page=' + page + "&size=" + size;
        if (date_start != null && date_start != "") {
            url += '&start_date=' + date_start;
        }
        if (date_finish != null && date_finish != "") {
            url += "&end_date=" + date_finish;
        }
        if (sort_field != null) {
            url += "&order_by=" + (sort_field) + "&ordering=" + (ordering ? "asc" : "desc")
        }
        if (query != null && query != "") {
            url += "&query=" + query
        }
        apiClient.get(url, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Загрузка параметров оплаты
    getPaymentParams(cb) {
        apiClient.get('/backoffice/payments/params', {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Покупка алмазов
    buyDiamonds(diamonds, email, cb) {
        apiClient.post('/backoffice/account/buy-diamonds?diamonds_amount=' + parseInt(diamonds, 10) + "&email=" + email, {}, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Загрузка списка инвойсов
    loadInvoices(query, sort_field, ordering, page, size, cb) {
        var url = '/backoffice/account/invoices?query=' + query + "&page=" + page + "&size=" + size
        if (sort_field != null) {
            url += "&order_by=" + (sort_field) + "&ordering=" + (ordering ? "asc" : "desc")
        }
        apiClient.get(url, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Создать турнир
    createTournament(club_id, tournament_data, cb) {
        var url = '/backoffice/club/' + club_id + '/create-tournament';
        var tournament = Object.assign({}, tournament_data);
        var prizes = tournament.prizes;
        delete tournament.prizes;
        apiClient.post(url, {
            data: tournament,
            prizes: prizes
        }, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },

    //API Обновить турнир
    updateTournament(club_id, tournament_id, tournament_data, cb) {
        var url = '/backoffice/tournaments/' + tournament_id + '/edit';
        apiClient.post(url, tournament_data,
            {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Отменить турнир
    cancelTournament(club_id, tournament_id, cb) {
        var url = '/backoffice/club/' + club_id + '/tournaments/' + tournament_id + '/cancel';
        apiClient.post(url, {}, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Загрузка аватара для турнира
    uploadTournamentAvatar(club_id, tournament_id, file, cb) {
        var url = '/backoffice/tournaments/' + tournament_id + '/update-logo';
        const formData = new FormData();
        formData.append('logo', file);

        apiClient.post(url, formData, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
    //API Отправка push уведомления
    sendPush(club_id, tournament_id, message, forParticipants, cb) {
        var url = '/backoffice/tournaments/' + tournament_id + '/custom-push';

        apiClient.post(url, {
            custom_msg: message,
            for_participants: forParticipants
        }, {headers: {'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`}})
            .then(response => {
                cb(response.data, null)
            })
            .catch(error => {
                // console.error(error)
                this.handleError(error)
                cb(null, error)
            });
    },
};

const authContext = createContext();

function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

function useAuth() {
    return useContext(authContext);
}

function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [lastError, setLastError] = useState(null);//{code:"error",message:"Something get wrong"}
    const [club, setClub] = useState(localStorage.getItem(CLUB_KEY) != null ? {id: localStorage.getItem(CLUB_KEY)} : null);
    const [loggedIn, setLoggedIn] = useState(
        localStorage.getItem(TOKEN_KEY) != null || false
    );


    const handleError = (error) => {
        if (error !== undefined && error != null && error.response) {
            if (error.response.data.code !== undefined && error.response.data.code != "invalid_auth_token") {
                if (error.response.data.code == "club_member_not_found") {
                    setLastError({
                        code: "",
                        message: "Вы не являетесь Владельцем или Менеджером клуба"
                    })
                } else {
                    setLastError(error.response.data)
                }
            }
        }
    }

    const signin = (email, password, cb) => {
        return Auth.signin(email, password, (user, error) => {
            if (user != null) {
                setUser(user);
                setLoggedIn(true);
            }
            cb(user, error);
        });
    };

    const signout = cb => {
        return Auth.signout(() => {
            setLoggedIn(false);
            setUser(null);
            setClub(null);
            localStorage.removeItem(CLUB_KEY)
            cb();
        });
    };

    const getProfile = cb => {
        return Auth.getProfile((user) => {
            setUser(user);
            if (user == null) {
                setLoggedIn(false);
            }
            cb();
        });
    };

    const restoreAuth = cb => {
        return Auth.restoreAuth((user) => {
            setUser(user);
            if (user == null) {
                setLoggedIn(false);
                cb();
            } else {
                //Восстанавливаем клуб
                if (localStorage.getItem(CLUB_KEY) != null) {
                    loadClub(localStorage.getItem(CLUB_KEY), cb)
                } else {
                    cb();
                }
            }
        });
    };

    const loadClubsList = (query, sort, sortAsc, page, size, cb) => {
        return Auth.loadClubs(query, sort, sortAsc, page, size, (clubs, error) => {
            cb(clubs);
            handleError(error)
        });
    };

    const loadClub = (club_id, cb) => {
        return Auth.loadClub(club_id, (club_info, error) => {
            setClub(club_info)
            if (club_info != null) {
                localStorage.setItem(CLUB_KEY, club_info.id)
            } else {
                localStorage.removeItem(CLUB_KEY)
            }
            cb(club_info);
            handleError(error)
        });
    };

    const loadMembersList = (query, sort_field, ordering, page, size, cb) => {
        if (club == null) {
            cb(null)
            return;
        }
        return Auth.loadMembers(club.id, query, sort_field, ordering, page, size, (members, error) => {
            cb(members);
            handleError(error)
        });
    };
    const changeMemberBalance = (member_account_id, diff, cb) => {
        if (club == null) {
            cb(null)
            return;
        }
        return Auth.changeMemberBalance(club.id, member_account_id, diff, (answer, error) => {
            cb(answer);
            handleError(error)
        });
    };

    const loadGames = (date_start, date_finish, done_reason_filter, query, sort_field, ordering, page, size, cb) => {
        if (club == null) {
            cb(null)
            return;
        }
        return Auth.loadGames(club.id, date_start, date_finish, done_reason_filter, query, sort_field, ordering, page, size, (games, error) => {
            cb(games);
            handleError(error)
        });
    }
    const loadGame = (game_id, cb) => {
        if (club == null) {
            cb(null)
            return;
        }
        return Auth.loadGame(club.id, game_id, (games, error) => {
            cb(games);
            handleError(error)
        });
    }
    const loadTournaments = (date_start, date_finish, status, sort_field, ordering, page, size, cb) => {
        if (club == null) {
            cb(null)
            return;
        }
        return Auth.loadTournaments(club.id, date_start, date_finish, status, sort_field, ordering, page, size, (games, error) => {
            cb(games);
            handleError(error)
        });
    }
    const loadTransactions = (date_start, date_finish, query, sort_field, ordering, page, size, cb) => {
        if (club == null) {
            cb(null)
            return;
        }
        return Auth.loadTransactions(club.id, date_start, date_finish, query, sort_field, ordering, page, size, (games, error) => {
            cb(games);
            handleError(error)
        });
    }
    const getPaymentParams = (cb) => {
        return Auth.getPaymentParams((params, error) => {
            cb(params);
            handleError(error)
        });
    };
    const buyDiamonds = (diamonds, email, cb) => {
        return Auth.buyDiamonds(diamonds, email, (invoice, error) => {
            cb(invoice);
            handleError(error)
        });
    };
    const loadInvoiceList = (query, sort, sortAsc, page, size, cb) => {
        return Auth.loadInvoices(query, sort, sortAsc, page, size, (invoices, error) => {
            cb(invoices);
            handleError(error)
        });
    };

    const createTournament = (tournament, cb) => {
        if (club == null) {
            cb(null)
            return;
        }
        return Auth.createTournament(club.id, tournament, (new_tournament, error) => {
            cb(new_tournament, error);
            // handleError(error)
        });
    };

    const updateTournament = (tournament_id, tournament_data, cb) => {
        if (club == null) {
            cb(null)
            return;
        }
        return Auth.updateTournament(club.id, tournament_id, tournament_data, (data, error) => {
            cb(data, error);
            handleError(error)
        });
    };

    const cancelTournament = (tournament_id, cb) => {
        if (club == null) {
            cb(null)
            return;
        }
        return Auth.cancelTournament(club.id, tournament_id, (data, error) => {
            cb(data, error);
            handleError(error)
        });
    };

    const uploadTournamentAvatar = (tournament_id, file, cb) => {
        if (club == null) {
            cb(null)
            return;
        }
        return Auth.uploadTournamentAvatar(club.id, tournament_id, file, (data, error) => {
            cb(data, error);
            // handleError(error)
        });
    };

    const sendPush = (tournament_id, message, forParticipants, cb) => {
        if (club == null) {
            cb(null)
            return;
        }
        return Auth.sendPush(club.id, tournament_id, message, forParticipants, (data, error) => {
            cb(data, error);
            // handleError(error)
        });
    };

    function humanizeNumber(x) {
        if (!x) return 0;
        return x.replace(/\.?0*$/, '');
    }

    return {
        loggedIn,
        user,
        club,
        setClub,
        signin,
        signout,
        getProfile,
        restoreAuth,
        loadClubsList,
        loadClub,
        loadMembersList,
        changeMemberBalance,
        loadGame,
        loadGames,
        loadTournaments,
        loadTransactions,
        getPaymentParams,
        buyDiamonds,
        loadInvoiceList,
        lastError,
        setLastError,
        createTournament,
        cancelTournament,
        uploadTournamentAvatar,
        updateTournament,
        sendPush,
    };
}

export {ProvideAuth, useProvideAuth, useAuth}
