import React from 'react';
import './App.css';
import './scss/style.scss';
import AuthRoute from "./layout/AuthRoute";
import {ProvideAuth} from "./app/auth"
import {ToastProvider} from 'react-toast-notifications'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/login/Login'));
const NardPlayer = React.lazy(() => import('./views/player/NardPlayer'));

const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

function App() {
  return (
      <ToastProvider autoDismiss={true}>
        <ProvideAuth>
          <Router>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route path="/login" exact render={props => <Login {...props}/>}/>
                <AuthRoute path="/">
                  <DefaultLayout/>
                </AuthRoute>
              </Switch>
            </React.Suspense>
          </Router>
        </ProvideAuth>
      </ToastProvider>
  );
}

export default App;
