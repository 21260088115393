import axios from 'axios';

const DEBUG = process.env.NODE_ENV === "development";
const URL = process.env.REACT_APP_URL;


const apiClient = axios.create({
    baseURL: URL,
    // withCredentials: true,
    headers:{
        'Access-Control-Allow-Origin': '*'
    }
});
apiClient.interceptors.response.use(function(response){
    if (DEBUG) { console.info("✉️ ", response); }
    return response;
}, function(error){
    if (DEBUG) { console.info("✉️ ", error); }
    return Promise.reject(error);
    // if(error.response.status !== 419) return Promise.reject(error);
    // window.location.reload();
});


export default apiClient;

