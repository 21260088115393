import React, {useEffect, useState} from 'react';
import {
    Redirect,
    Route,
    Switch, useHistory, useLocation, useParams
} from 'react-router-dom'
import {useAuth} from '../app/auth'

function AuthRoute({ children, ...rest }) {
    let auth = useAuth();

    useEffect(() => {
        if (auth.user == null){
            auth.restoreAuth(()=>{});
        }
    });

    return (
        <Route
            {...rest}
            render={({location}) => {

                return auth.loggedIn ? (
                        (children)
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
            }
        />
    );
}
export default AuthRoute
